import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="خطای 404 - مسیر پیدا نشد." />
    <h1>خطای 404 - مسیر پیدا نشد.</h1>
    <p>مسیر درخواستی پیدا نشد.</p>
  </Layout>
)

export default NotFoundPage
